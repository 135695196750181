import { type MutationOptions, useMutation } from "@tanstack/react-query";
import { useMutationCustomOptions } from "hooks/useMutationCustomOptions";
import { signin } from "network/account/accountApi";
import type { Account, SigninBody } from "types/account.type";

const useAccountSinginCreateMutationMutation = (
  options: MutationOptions<Account, unknown, SigninBody, unknown>
) => {
  const customOptions = useMutationCustomOptions(options, ["accountInfo"]);

  const {
    mutate: signinAccount,
    isPending: signinAccountLoading,
    error: signinAccountError
  } = useMutation<Account, unknown, SigninBody, unknown>({
    mutationFn: (body: SigninBody) => signin(body),
    ...customOptions
  });

  return {
    signinAccount,
    signinAccountLoading,
    signinAccountError
  };
};

export default useAccountSinginCreateMutationMutation;
