import { yupResolver } from "@hookform/resolvers/yup";
import { settings } from "configs/settings";
import { tokens } from "configs/tokens";
import useAccountSinginCreateMutation from "hooks/mutations/useAccountSinginCreateMutation";
import useAppLayoutState from "hooks/recoilState/useAppLayoutState";
import useRefreshingStatus from "hooks/recoilState/useRefreshingStatus";
import { useAlertSnackbar } from "layout/hooks/useAlertSnackbar";
import { useRouter } from "next/router";
import { RoleSlugEnum } from "op-business";
import psl from "psl";
import { useCookies } from "react-cookie";
import { type FieldErrors, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { yup } from "shared-validation";
import { AccountState } from "store/accountRecoil";
import type { Account, SigninBody, Token } from "types/account.type";
import { setCookie } from "utils/cookie";

const createScheme = {
  phone: yup.string().required(),
  password: yup.string().required()
};

const schema = yup.object().shape(createScheme).required();

type FormValues = yup.InferType<typeof schema>;

export const useLogin = (reset?: () => void) => {
  const host = settings.ORIGIN_VUE_OPS;

  const router = useRouter();
  const [, setAccountState] = useRecoilState(AccountState);
  const [, setAppLayoutState] = useAppLayoutState();
  const [refreshStatus, setRefreshStatus] = useRefreshingStatus();
  const { handleClose } = useAlertSnackbar();
  const { signinAccount } = useAccountSinginCreateMutation({
    onSuccess: (data: Account) => afterLogin(data),
    onError: () => router.replace("/login")
  });

  const { signinAccount: triggerRefresh } = useAccountSinginCreateMutation({
    onSuccess: (data: Account) => {
      afterLogin(data);
      handleClose();
      setRefreshStatus({ isRefreshing: false });
      reset && reset();
    },
    onError: () => router.replace("/login")
  });

  const [cookies] = useCookies([tokens.ACCESS_TOKEN_NAME]);
  const cookieRefreshToken = cookies[tokens.REFRESH_TOKEN_NAME] as string;
  const cookieAccessToken = cookies[tokens.ACCESS_TOKEN_NAME] as string;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      phone: "",
      password: ""
    },
    resolver: yupResolver(schema)
  });

  const onAuthError = (err) => {
    const status = err?.response?.status;
    const isAuthError =
      (status === 401 && !cookieAccessToken) || status === 460;
    if (cookieRefreshToken && !refreshStatus.isRefreshing) {
      if (isAuthError) {
        setRefreshStatus({ isRefreshing: true });
        refreshToken(cookieRefreshToken);
      } else {
        router.push("/login");
        setAccountState(null);
        setAppLayoutState({ isLayoutHidden: true });
      }
    }
  };

  const afterLogin = (data: Account) => {
    setAccessToken(data.token);
    setAccountState(data);
    if (router.pathname === "/login") {
      if (data.role_slug === RoleSlugEnum.CustomerManager) {
        window.location.href = host + "/user/real-time";
      } else {
        router.push("/dashboard");
      }
    }
  };

  const setAccessToken = (token: Token) => {
    const parsed = psl.parse(window.location.hostname);
    const domain = parsed.domain;

    setCookie(tokens.ACCESS_TOKEN_NAME, token.access_token, {
      maxAge: token.expires_in,
      path: "/",
      domain
    });

    setCookie(tokens.REFRESH_TOKEN_NAME, token.refresh_token, {
      path: "/",
      domain
    });
    setAppLayoutState({ isLayoutHidden: false });
  };

  const refreshToken = async (refresh_token: string) => {
    setRefreshStatus({ isRefreshing: true });
    triggerRefresh({
      grant_type: "refresh_token",
      app: 1,
      refresh_token
    });
  };

  const onSubmit = (data: SigninBody) => {
    const body: SigninBody = {
      ...data,
      grant_type: "password",
      app: 1
    };

    signinAccount(body);
  };

  const onError = (errors: FieldErrors<SigninBody>) => {
    console.log(errors);
  };

  return {
    onSubmit,
    onError,
    onAuthError,
    handleSubmit,
    setAccessToken,
    refreshToken,
    control,
    errors
  };
};
