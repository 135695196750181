type Settings = {
  ORIGIN_VUE_OPS: string;
  ORIGIN_REACT_OPS: string;
  API_DOMAIN: string;
  CDN_DOMAIN: string;
  VEHICLE_API_DOMAIN: string;
  MODE?: string;
};

const ENV_MODE = process.env.NEXT_PUBLIC_MODE;

const isLocal =
  ENV_MODE === "development" && process.env.NEXT_PUBLIC_LOCAL_MODE === "true";

const devDefaultSettings: Settings = {
  ORIGIN_VUE_OPS: "https://ops-dev.elecle.me",
  ORIGIN_REACT_OPS: "https://op2-dev.elecle.me",
  API_DOMAIN: "https://c.elecle.me",
  CDN_DOMAIN: "https://d12plrb6tqz2pw.cloudfront.net/assets",
  VEHICLE_API_DOMAIN: "https://landmarker.elecle.me"
};

const prodDefaultSettings: Settings = {
  ORIGIN_VUE_OPS: "https://ops.elecle.bike",
  ORIGIN_REACT_OPS: "https://op2.elecle.bike",
  API_DOMAIN: "https://c.elecle.bike",
  CDN_DOMAIN: "https://cdn.elecle.bike/assets",
  VEHICLE_API_DOMAIN: "https://landmarker.elecle.bike"
};

const environmentSettings: { [key: string]: Settings } = {
  development: {
    ...devDefaultSettings,
    MODE: "development"
  },
  localDevelopment: {
    ...devDefaultSettings,
    MODE: "local",
    ORIGIN_VUE_OPS: "http://localhost:8099",
    ORIGIN_REACT_OPS: "http://localhost:3000"
  },
  production: {
    ...prodDefaultSettings,
    MODE: "production"
  },
  staging: {
    ...prodDefaultSettings,
    MODE: "staging"
  }
};

export const settings: Partial<Settings> = {
  ...(ENV_MODE === "production" && environmentSettings.production),
  ...(ENV_MODE === "staging" && environmentSettings.staging),
  ...(ENV_MODE === "development" && environmentSettings.development),
  ...(isLocal && environmentSettings.localDevelopment)
};
