import useAccountSingoutCreateMutation from "hooks/mutations/useAccountSingoutCreateMutation";
import { useRouter } from "next/router";
import { useRecoilState } from "recoil";
import { AccountState } from "store/accountRecoil";
import { removeAllCookies } from "utils/cookie";

export const useAuth = () => {
  const router = useRouter();
  const [, setAccountState] = useRecoilState(AccountState);

  const { signOutAccount } = useAccountSingoutCreateMutation({
    onSuccess: () => {
      removeAllCookies();
      setAccountState(null);
      router.push("/login");
    }
  });

  const postSignout = async () => {
    signOutAccount();
  };
  return {
    postSignout
  };
};
