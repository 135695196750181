import { type MutationOptions, useMutation } from "@tanstack/react-query";
import { useMutationCustomOptions } from "hooks/useMutationCustomOptions";
import { signout } from "network/account/accountApi";

const useAccountSingoutCreateMutation = (options?: MutationOptions) => {
  const customOptions = useMutationCustomOptions(options);

  const {
    mutate: signOutAccount,
    isPending: signOutAccountLoading,
    error: signOutAccountError
  } = useMutation({
    mutationFn: signout,
    ...customOptions
  });

  return {
    signOutAccount,
    signOutAccountLoading,
    signOutAccountError
  };
};

export default useAccountSingoutCreateMutation;
