import Snackbar from "@mui/material/Snackbar";
import useErrorHandler from "hooks/useErrorHandler";
import { useAlertSnackbar } from "./hooks/useAlertSnackbar";

function AlertSnackbar() {
  const { isAlertSnackbarOpen, handleClose, alertMessage } = useAlertSnackbar();
  useErrorHandler();

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      color="primary"
      open={isAlertSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      message={alertMessage}
      ContentProps={{ sx: { background: "#ff2100" } }}
      sx={{ textAlign: "right" }}
    />
  );
}
export default AlertSnackbar;
