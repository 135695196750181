import httpClient from "configs/httpClient";
import { useAlertSnackbar } from "layout/hooks/useAlertSnackbar";

export default function useErrorHandler() {
  const { openAlertSnackbar } = useAlertSnackbar();

  const errorHandler = (err: Error, callback?: (err: Error) => void) => {
    alertErrorMessage(err);
    callback && callback(err);
    return;
  };

  const alertErrorMessage = (err) => {
    const res = err.response;

    if (!res?.data) return;

    let message = `[${res.status}] ${res.data?.message}`;

    if (res.data.alert) {
      alert(res.data.alert);
    } else if (res.data.detail) {
      message = `[${res.status}]${res.data.detail}`;
    } else if (res.data.code) {
      message = `[${res.status}]${res.data.message}(${res.data.code})`;
    }

    openAlertSnackbar(message);
    return Promise.reject(err);
  };

  httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const res = error.response;

      if (!res?.data) return Promise.reject(error);

      let message = `[${res.status}] ${res.data?.message}`;

      if (res.data.alert) {
        alert(res.data.alert);
      } else if (res.data.detail) {
        message = `[${res.status}]${res.data.detail}`;
      } else if (res.data.code) {
        message = `[${res.status}]${res.data.message}(${res.data.code})`;
      }

      openAlertSnackbar(message);
      return Promise.reject(error);
    }
  );

  return {
    errorHandler,
    alertErrorMessage
  };
}
