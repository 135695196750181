import {
  type DefaultError,
  type MutationOptions,
  type QueryKey,
  useQueryClient
} from "@tanstack/react-query";

export const useMutationCustomOptions = <
  TData,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(
  options?: MutationOptions<TData, TError, TVariables, TContext>,
  queryKey?: QueryKey
) => {
  const queryClient = useQueryClient();

  const onCustomSuccess = (
    data: TData,
    variables: TVariables,
    context: TContext
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (queryKey) queryClient.invalidateQueries({ queryKey });
    if (options?.onSuccess) options.onSuccess(data, variables, context);
  };

  return {
    ...options,
    onSuccess: onCustomSuccess
  };
};
